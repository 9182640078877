"use client";
import React, { useState, useEffect, useRef } from "react";
import {
  Code,
  Smartphone,
  Layers,
  Cloud,
  Shield,
  PieChart,
  ArrowRight,
} from "lucide-react";
import TrustBadge from "../Main/TrustBadge";
import Link from "next/link";

// Counter Hook
const useCounter = (end, duration = 2000, inView) => {
  const [count, setCount] = useState(0);
 
  useEffect(() => {
    if (!inView) return;
    let startTime;
    let animationFrame;
    const step = (timestamp) => {
      if (!startTime) startTime = timestamp;
      const progress = Math.min((timestamp - startTime) / duration, 1);
      setCount(Math.floor(progress * end));
      if (progress < 1) {
        animationFrame = requestAnimationFrame(step);
      }
    };
    animationFrame = requestAnimationFrame(step);
    return () => cancelAnimationFrame(animationFrame);
  }, [end, duration, inView]);
  return count;
};

// Counter Component
const Counter = ({ end, label }) => {
  const [inView, setInView] = useState(false);
  const ref = useRef(null);
  const count = useCounter(end, 2000, inView);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setInView(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <div 
      ref={ref} 
      className="bg-white/80 backdrop-blur-sm p-8 rounded-2xl text-center shadow-lg hover:shadow-xl transition-all duration-300 hover:-translate-y-1 border border-blue-100"
    >
      <div className="text-4xl font-bold text-blue-600 mb-2">{count}+</div>
      <div className="text-sm text-gray-600 font-medium">{label}</div>
    </div>
  );
};

const HeroSection = () => {
  const staticPositions = [
    { top: "15%", left: "10%", delay: "0s" },
    { top: "25%", right: "15%", delay: "0.5s" },
    { top: "65%", left: "15%", delay: "1s" },
    { top: "70%", right: "10%", delay: "1.5s" },
    { top: "20%", left: "60%", delay: "2s" },
    { top: "45%", left: "5%", delay: "2.5s" },
  ];

  const icons = [Code, Smartphone, Layers, Cloud, Shield, PieChart];
  const words = [
    "innovative software",
    "scalable cloud solutions",
    "cutting-edge mobile apps",
    "AI-powered insights",
    "secure digital platforms",
  ];

  const counters = [
    { value: 13, label: "Years of Experience" },
    { value: 1500, label: "Projects Delivered" },
    { value: 500, label: "Satisfied Clients" },
    { value: 50, label: "Technologies Mastered" },
  ];

  const [currentWord, setCurrentWord] = React.useState("");
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);

  React.useEffect(() => {
    setIsVisible(true);
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(
      () => {
        if (!isDeleting && currentWord === words[currentIndex]) {
          setTimeout(() => setIsDeleting(true), 1000);
          return;
        }
        if (isDeleting && currentWord === "") {
          setIsDeleting(false);
          setCurrentIndex((prevIndex) => (prevIndex + 1) % words.length);
          return;
        }
        setCurrentWord((prev) =>
          isDeleting
            ? prev.slice(0, -1)
            : words[currentIndex].slice(0, prev.length + 1)
        );
      },
      isDeleting ? 50 : 150
    );
    return () => clearTimeout(timer);
  }, [currentWord, currentIndex, isDeleting]);

  return (
    <section className="relative min-h-screen bg-gradient-to-b from-white to-blue-50 py-16 overflow-hidden">
      {/* Animated background elements */}
      <div className="absolute inset-0 z-0">
        <div className="absolute top-20 right-20 w-96 h-96 bg-blue-100/30 rounded-full blur-3xl animate-spin-slow" />
        <div className="absolute -bottom-32 -left-32 w-[40rem] h-[40rem] bg-blue-200/30 rounded-full blur-3xl animate-spin-slow-reverse" />
      </div>

      {/* Background icons with floating animations */}
      <div className="absolute top-0 left-0 w-full h-full pointer-events-none z-0">
        {icons.map((Icon, index) => (
          <div
            key={index}
            className="absolute animate-float transition-all duration-1000"
            style={{
              ...staticPositions[index],
              animationDelay: staticPositions[index].delay,
              opacity: isVisible ? 0.15 : 0,
              transform: `scale(${isVisible ? 1 : 0})`,
            }}
          >
            <Icon className="sm:w-24 sm:h-24 w-16 h-16 text-blue-600" />
          </div>
        ))}
      </div>

      {/* Main content */}
      <div className="container mx-auto px-4 pt-16 max-w-7xl relative z-10">
        <div className="w-full text-center mb-16">
          <div className={`transform transition-all duration-1000 ${isVisible ? 'translate-y-0 opacity-100' : '-translate-y-4 opacity-0'}`}>
            <TrustBadge />
          </div>

          <h1 className={`sm:text-7xl text-4xl font-bold mb-8 leading-tight text-center transition-all duration-1000 delay-300 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}>
            Empowering your business with{" "}
            <div className="text-blue-600 min-h-[1.5em] my-4">
              {currentWord}
              <span className="animate-blink ml-1">|</span>
            </div>
          </h1>

          <p className={`text-center mb-12 max-w-3xl mx-auto text-lg text-gray-600 transition-all duration-1000 delay-500 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}>
            With over a decade of experience, we specialize in delivering
            tailored solutions that drive efficiency, enhance user experience,
            and foster growth for businesses of all sizes.
          </p>

          <div className={`mb-24 transition-all duration-1000 delay-700 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-4 opacity-0'}`}>
            <Link
              href="/contact/"
              className="inline-flex items-center gap-2 bg-blue-600 text-white px-8 py-4 rounded-full font-medium hover:bg-blue-700 hover:scale-105 active:scale-95 transition-all duration-300 shadow-lg hover:shadow-xl"
            >
              Get Started <ArrowRight className="w-5 h-5" />
            </Link>
          </div>
        </div>

        {/* Counters Section */}
        <div className={`transition-all duration-1000 delay-1000 ${isVisible ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'}`}>
          <div className="grid sm:grid-cols-4 gap-6">
            {counters.map((counter, index) => (
              <Counter key={index} end={counter.value} label={counter.label} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;